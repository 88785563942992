import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div style={{ background: "rgb(33,33,33)" }}>
      <h1>NOT FOUND</h1>
      <p>
        You just hit a route that doesn&#39;t exist.<a href={"/"}>Go Back to safety?</a>
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
